import revive_payload_client_N2NLAwg2y4 from "/vercel/path0/node_modules/.pnpm/nuxt@3.7.3_@types+node@18.17.19_eslint@8.50.0_less@4.2.0_stylelint@14.16.1_typescript@5.2.2_vue-tsc@1.8.13/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_wZCoYRWrbL from "/vercel/path0/node_modules/.pnpm/nuxt@3.7.3_@types+node@18.17.19_eslint@8.50.0_less@4.2.0_stylelint@14.16.1_typescript@5.2.2_vue-tsc@1.8.13/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_gpF50XOYV6 from "/vercel/path0/node_modules/.pnpm/nuxt@3.7.3_@types+node@18.17.19_eslint@8.50.0_less@4.2.0_stylelint@14.16.1_typescript@5.2.2_vue-tsc@1.8.13/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_x6gXxqUk2X from "/vercel/path0/node_modules/.pnpm/nuxt@3.7.3_@types+node@18.17.19_eslint@8.50.0_less@4.2.0_stylelint@14.16.1_typescript@5.2.2_vue-tsc@1.8.13/node_modules/nuxt/dist/app/plugins/payload.client.js";
import plugin_vue3_M22hs5f0ui from "/vercel/path0/node_modules/.pnpm/@pinia+nuxt@0.4.11_typescript@5.2.2_vue@3.3.4/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/vercel/path0/.nuxt/components.plugin.mjs";
import prefetch_client_Kj7Sbm50OP from "/vercel/path0/node_modules/.pnpm/nuxt@3.7.3_@types+node@18.17.19_eslint@8.50.0_less@4.2.0_stylelint@14.16.1_typescript@5.2.2_vue-tsc@1.8.13/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import templates_pwa_client_490f3a9d_bWMsPgTybc from "/vercel/path0/.nuxt/templates.pwa.client.490f3a9d.ts";
import plugin_ix3yELujAY from "/vercel/path0/node_modules/.pnpm/@digitalnatives+graphql-client@4.0.3_graphql@16.8.1_nuxt@3.7.3_vue@3.3.4/node_modules/@digitalnatives/graphql-client/dist/runtime/plugin.mjs";
import plugin_v2vBc5WWLo from "/vercel/path0/node_modules/.pnpm/nuxt-bugsnag@7.0.0/node_modules/nuxt-bugsnag/dist/runtime/client/plugin.mjs";
import chunk_reload_client_AJVS849SaM from "/vercel/path0/node_modules/.pnpm/nuxt@3.7.3_@types+node@18.17.19_eslint@8.50.0_less@4.2.0_stylelint@14.16.1_typescript@5.2.2_vue-tsc@1.8.13/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
export default [
  revive_payload_client_N2NLAwg2y4,
  unhead_wZCoYRWrbL,
  router_gpF50XOYV6,
  payload_client_x6gXxqUk2X,
  plugin_vue3_M22hs5f0ui,
  components_plugin_KR1HBZs4kY,
  prefetch_client_Kj7Sbm50OP,
  templates_pwa_client_490f3a9d_bWMsPgTybc,
  plugin_ix3yELujAY,
  plugin_v2vBc5WWLo,
  chunk_reload_client_AJVS849SaM
]