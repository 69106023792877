import { default as _91uid_93UXkUdxnEpKMeta } from "/vercel/path0/pages/[uid].vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as manualsMRu1NxJWkMeta } from "/vercel/path0/pages/manual.vue?macro=true";
import { default as monitorWr3u9H9wSIMeta } from "/vercel/path0/pages/monitor.vue?macro=true";
import { default as nfcNyq3xWEuElMeta } from "/vercel/path0/pages/nfc.vue?macro=true";
import { default as _12oU4WGecvRMeta } from "/vercel/path0/pages/streams/1.vue?macro=true";
import { default as _2nNCATQODojMeta } from "/vercel/path0/pages/streams/2.vue?macro=true";
import { default as _3pzD3SbCI0PMeta } from "/vercel/path0/pages/streams/3.vue?macro=true";
import { default as _46e37z4U4GqMeta } from "/vercel/path0/pages/streams/4.vue?macro=true";
export default [
  {
    name: _91uid_93UXkUdxnEpKMeta?.name ?? "uid",
    path: _91uid_93UXkUdxnEpKMeta?.path ?? "/:uid()",
    meta: _91uid_93UXkUdxnEpKMeta || {},
    alias: _91uid_93UXkUdxnEpKMeta?.alias || [],
    redirect: _91uid_93UXkUdxnEpKMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[uid].vue").then(m => m.default || m)
  },
  {
    name: indexe9Brt5DfdhMeta?.name ?? "index",
    path: indexe9Brt5DfdhMeta?.path ?? "/",
    meta: indexe9Brt5DfdhMeta || {},
    alias: indexe9Brt5DfdhMeta?.alias || [],
    redirect: indexe9Brt5DfdhMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: manualsMRu1NxJWkMeta?.name ?? "manual",
    path: manualsMRu1NxJWkMeta?.path ?? "/manual",
    meta: manualsMRu1NxJWkMeta || {},
    alias: manualsMRu1NxJWkMeta?.alias || [],
    redirect: manualsMRu1NxJWkMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/manual.vue").then(m => m.default || m)
  },
  {
    name: monitorWr3u9H9wSIMeta?.name ?? "monitor",
    path: monitorWr3u9H9wSIMeta?.path ?? "/monitor",
    meta: monitorWr3u9H9wSIMeta || {},
    alias: monitorWr3u9H9wSIMeta?.alias || [],
    redirect: monitorWr3u9H9wSIMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/monitor.vue").then(m => m.default || m)
  },
  {
    name: nfcNyq3xWEuElMeta?.name ?? "nfc",
    path: nfcNyq3xWEuElMeta?.path ?? "/nfc",
    meta: nfcNyq3xWEuElMeta || {},
    alias: nfcNyq3xWEuElMeta?.alias || [],
    redirect: nfcNyq3xWEuElMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/nfc.vue").then(m => m.default || m)
  },
  {
    name: _12oU4WGecvRMeta?.name ?? "streams-1",
    path: _12oU4WGecvRMeta?.path ?? "/streams/1",
    meta: _12oU4WGecvRMeta || {},
    alias: _12oU4WGecvRMeta?.alias || [],
    redirect: _12oU4WGecvRMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/streams/1.vue").then(m => m.default || m)
  },
  {
    name: _2nNCATQODojMeta?.name ?? "streams-2",
    path: _2nNCATQODojMeta?.path ?? "/streams/2",
    meta: _2nNCATQODojMeta || {},
    alias: _2nNCATQODojMeta?.alias || [],
    redirect: _2nNCATQODojMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/streams/2.vue").then(m => m.default || m)
  },
  {
    name: _3pzD3SbCI0PMeta?.name ?? "streams-3",
    path: _3pzD3SbCI0PMeta?.path ?? "/streams/3",
    meta: _3pzD3SbCI0PMeta || {},
    alias: _3pzD3SbCI0PMeta?.alias || [],
    redirect: _3pzD3SbCI0PMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/streams/3.vue").then(m => m.default || m)
  },
  {
    name: _46e37z4U4GqMeta?.name ?? "streams-4",
    path: _46e37z4U4GqMeta?.path ?? "/streams/4",
    meta: _46e37z4U4GqMeta || {},
    alias: _46e37z4U4GqMeta?.alias || [],
    redirect: _46e37z4U4GqMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/streams/4.vue").then(m => m.default || m)
  }
]