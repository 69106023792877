import { villusBugsnag, craftPreview, bearerToken } from '@digitalnatives/villus-plugins';
import { defaultPlugins } from 'villus';

export default defineGraphqlConfig(() => {
    const config = useRuntimeConfig();
    return {
        villus: {
            url: config.public.apiEndpoint,
            use: [villusBugsnag(), bearerToken(config.public.apiToken), craftPreview(), ...defaultPlugins()],
        }
    };
});
