<template>
    <div class="error">
        <h1 v-if="error && error.statusCode === 404">Page not found</h1>
        <h1 v-else>An error occurred</h1>

        <p v-if="error && error.message">{{ error.message }}</p>
    </div>
</template>

<script>
export default {
    props: {
        error: {
            type: Object,
            required: false,
            default: null
        }
    }
};
</script>
