<template>
    <div class="template template--base">
        <slot
            v-if="!!$slots.hero"
            name="hero"
        />

        <main id="main-content-landmark">
            <slot />
        </main>
    </div>
</template>
